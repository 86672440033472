import 'es6-promise/auto';

export function loadPolyfills() {
  return new Promise(resolve => {
    if (
      'startsWith' in String.prototype &&
      'endsWith' in String.prototype &&
      'includes' in Array.prototype &&
      'assign' in Object &&
      'keys' in Object
    ) {
      resolve();
      return;
    }

    Promise.all([
      import(/* webpackChunkName: "es-polyfills" */ 'core-js/es/array'),
      import(/* webpackChunkName: "es-polyfills" */ 'core-js/es/object'),
      import(/* webpackChunkName: "es-polyfills" */ 'core-js/es/string'),
    ]).then(() => resolve());
  });
}
