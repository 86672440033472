export function onMount(callback) {
  $(callback);
}

export function isCurrentScreen(screenName) {
  return getCurrentScreenName() === screenName;
}

export function getCurrentScreenName() {
  return $('body').data('page');
}
