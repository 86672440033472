export default function AccountModal() {
  if (!$('#select-account-section').length) {
    return;
  }
  initAccountSection();
  initConfirmSection();

  $('#create-account-modal').on('hidden.bs.modal', () => showSection('SELECT'));
}

function initAccountSection() {
  const userAccounts = $('.user-account');
  const continueButton = $('#continue-button');

  userAccounts.on('click', function () {
    $('.selected').removeClass('selected');
    $(this).addClass('selected');
    // Hidden form field to be submitted on confirm
    $('#user-id').val(this.id);
    continueButton.prop('disabled', false);
  });

  continueButton.on('click', () => showSection('CONFIRM'));
}

function initConfirmSection() {
  const $form = $('#confirm-account-form');
  $form.on('ajax:success', () => showSection('SUCCESS'));
  $form.on('ajax:error', () => showSection('ERROR'));

  $('#go-back').on('click', () => showSection('SELECT'));
}

function showSection(name) {
  const selectAccountSection = $('#select-account-section');
  const confirmSection = $('#confirm-account-section');
  const successSection = $('#account-success-section');
  const errorSection = $('#account-error-section');
  const accountToConfirm = $('#selected-account');

  selectAccountSection.hide();
  confirmSection.hide();
  successSection.hide();
  errorSection.hide();

  switch (name) {
    case 'CONFIRM':
      const selectedAccount = $('.selected').clone();
      accountToConfirm.append(selectedAccount);
      confirmSection.show();
      break;
    case 'SELECT':
      selectAccountSection.show();
      accountToConfirm.empty();
      break;
    case 'SUCCESS':
      successSection.show();
      break;
    case 'ERROR':
      errorSection.show();
      break;
  }
}
