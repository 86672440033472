export default function addDateInputPlugin($) {
  $.fn.dateInput = function () {
    return this.each(function () {
      initDateInput($(this));
    });
  };

  function initDateInput(elem) {
    elem.addClass('u-date-input');
    var monthSelect = elem.find('select');
    var dayInput = elem.find('.u-date-day');
    var yearInput = elem.find('.u-date-year');
    var hiddenInput = elem.find('input[type="hidden"]');

    monthSelect.change(triggerChange);
    dayInput.on('input', triggerChange);
    yearInput.on('input', triggerChange);

    function triggerChange() {
      var year = yearInput.val().trim();
      var month = monthSelect.val();
      var day = dayInput.val().trim();
      var newValue = year && month && day ? year + '-' + month + '-' + day : '';
      hiddenInput.val(newValue);
      elem.trigger('u-date-change');
    }
  }

  // Add ability to get the current date value with the usual $(elem).val(); method
  var originalHook = $.valHooks.div;
  $.valHooks.div = {
    get: function(elem) {
      var $elem = $(elem);
      if ($elem.hasClass('u-date-input')) {
        return $elem.find('input[type="hidden"]').val();
      }
      return originalHook && originalHook.get(elem) || undefined;
    }
  };
}
