import { createFieldValidator } from './field-validation';
import { noop, getRequiredData } from '../helpers';

export default function addPlugin($) {
  $.fn.formValidator = function (config) {
    return this.each(function () {
      initPlugin($(this), config);
    });
  };
}

function initPlugin(formElem, config={}) {
  const messages = {};
  const onSubmitError = config.onSubmitError || noop;
  const onValidate = config.onValidate || noop;

  // Handle form submission
  formElem.submit(ev => {
    if (_.isEmpty(messages)) {
      // Continue with submission
      return;
    }
    // Prevent submission
    ev.preventDefault();
    formElem.addClass('u-val-show');
    onSubmitError(messages);
  });

  // Setup field validation
  formElem.find('.u-val').each(function(index) {
    const inputElem = $(this);
    const formGroup = inputElem.parent();

    const errorLabel = formGroup.find('.u-val-message');
    const fieldIdentifier = inputElem.attr('name') || formGroup.find('[name]').attr('name') || index;
    const eventType = getRequiredData(inputElem, 'validate-on', fieldIdentifier);

    const validateField = createFieldValidator(inputElem);
    const processMessage = errorMessage => {
      if (errorMessage) {
        errorLabel.text(errorMessage);
        formGroup.addClass('u-val-error');
        messages[fieldIdentifier] = errorMessage;
      } else if (messages[fieldIdentifier]) {
        errorLabel.text('');
        formGroup.removeClass('u-val-error');
        delete messages[fieldIdentifier];
      }
    };

    inputElem.on(eventType, () => {
      processMessage(validateField());
      onValidate(messages);
    });

    // Validate initial state
    processMessage(validateField());
  });

  // Report initial validation results
  onValidate(messages);
}
