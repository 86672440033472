export default function DocumentsAccordion() {
    let accord = document.getElementsByClassName("documents-accordion");
    let i;

    for (i = 0; i < accord.length; i++) {
        accord[i].addEventListener("click", function() {
            this.classList.toggle("doc-active");
            let panel = this.nextElementSibling;
            if (panel.style.display === "flex") {
                panel.style.display = "none";
            } else {
                panel.style.display = "flex";
            }
        });
    } 

}