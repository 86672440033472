export default function PaymentsScreen() {
  const paymentButton = $('#payment-button');
  const paymentForm = paymentButton.parents('form');
  const loading = $('#loading-message');
  const switchPaymentLink = $('#switch-payment');

  const errorContainer = $('#error-container');
  const errorMessage = $('#error-message');

  paymentButton.on('click', () => {
    paymentButton.addClass('hidden');
    switchPaymentLink.addClass('hidden');
    errorContainer.addClass('hidden');

    loading.removeClass('hidden');
  });

  paymentForm.on('ajax:error', (_e, xhr) => {
    paymentButton.removeClass('hidden');
    switchPaymentLink.removeClass('hidden');
    errorContainer.removeClass('hidden');

    loading.addClass('hidden');

    let message = 'Unable to process payment. Please try again later.';
    if (xhr.responseJSON && xhr.responseJSON.error) {
      message = xhr.responseJSON.error;
    }
    errorMessage.text(message);
  });
}

