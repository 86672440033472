import roundNumber from 'lodash/round';

export function round(number, numberOfDecimals=2) {
  return roundNumber(number, numberOfDecimals);
}

export function formatNumber(number, numberOfDecimals=2) {
  if (isNaN(number) || _.isNull(number)) {
    return '';
  }

  number = round(number, numberOfDecimals).toFixed(numberOfDecimals);
  const parts = number.split('.');
  const num = parts[0];
  const decimals = parts[1] ? '.' + parts[1] : '';
  return num.replace(/(\d)(?=(?:\d{3})+$)/g, '$1' + ',') + decimals;
}
