export function initNavbar() {
  initScrollToTop();

  // TODO Figure out what this does
  $('.navbar-collapse ul li a').click(function () {
    $('.navbar-toggle:visible').click();
  });

  initMobileMenu();
}

function initScrollToTop() {
  $('a.page-scroll').bind('click', function (event) {
    var $anchor = $(this);
    $('html, body')
      .stop()
      .animate(
        {
          scrollTop: $($anchor.attr('href')).offset().top,
        },
        1500,
        'easeInOutExpo'
      );
    event.preventDefault();
  });
}

function initMobileMenu() {
  const $menu = $('#menu');
  $menu.mmenu({
    offCanvas: {
      position: 'right',
    },
    extensions: ['effect-slide-menu', 'pageshadow'],
  });

  const api = $menu.data('mmenu');
  $('#open-account-modal').on('click', () => {
    api.close();
    setTimeout(() => $('#create-account-modal').modal(), 500);
  });
}
