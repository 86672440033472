import AccountModal from '../account-modal';

export default function CostsScreen() {
  AccountModal();

  $('input[name="payment-method"]').click(function() {
    $('#payment-instructions').toggle(this.value === 'bank-transfer');
    $('#pay-now').toggle(this.value === 'credit-card');
  });
}
