import { Loader } from '@googlemaps/js-api-loader';
import mapStyles from './map-styles';

export async function initGoogleMap(container) {
  const appData = JSON.parse(document.getElementById('__UMHAMBI_INIT__').textContent);
  const brandColour = getComputedStyle(document.documentElement).getPropertyValue('--colour-primary-500');
  const accommodations = appData.accommodations.map(accom => ({
    ...accom,
    gps_longitude: parseFloat(accom.gps_longitude),
    gps_latitude: parseFloat(accom.gps_latitude),
  }));

  const loader = new Loader({ apiKey: appData.maps_api_key, version: 'weekly' });
  const google = await loader.load();
  const map = new google.maps.Map(
    container,
    {
      mapTypeId: 'terrain',
      styles: mapStyles,
      backgroundColor: '#fafafa',
      // Turn off all the controls
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      disableDefaultUI: true,
      gestureHandling: 'none',
      keyboardShortcuts: false,
      scrollwheel: false,
    }
  );
  /*
    The bounds get extended by each accommodation's coordinates and then it gets used
    later on to correctly zoom and center the map
   */
  let mapBounds = new google.maps.LatLngBounds();
  const infoWindow = new google.maps.InfoWindow();

  // Add a marker for each accommodation
  const coordinates = [];
  accommodations.forEach(accom => {
    coordinates.push({ lat: accom.gps_latitude, lng: accom.gps_longitude });
    const position = new google.maps.LatLng(accom.gps_latitude, accom.gps_longitude);
    mapBounds.extend(position);
    const marker = new google.maps.Marker({
      map,
      position,
      title: accom.name,
      // Custom branded marker icon
      icon: {
        path: pinIconPath,
        strokeWeight: 1,
        strokeWidth: 1,
        strokeColor: '#ffffff',
        strokeOpacity: 0.9,
        fillOpacity: 1,
        fillColor: brandColour || '#000000',
        scale: 1.5,
        anchor: new google.maps.Point(12, 25),
      },
    });
    marker.addListener('click', () => {
      infoWindow.setContent(`<div class="text-black">${accom.name}</div>`);
      infoWindow.open({ anchor: marker, map, shouldFocus: false, });
    });
  });

  // Draw the lines between the markers
  const windowWidth = $(window).width();
  coordinates.forEach((coord, index) => {
    const nextCoord = coordinates[index + 1];
    if (nextCoord) {
      new google.maps.Polyline({
        map: map,
        path: [coord, nextCoord],
        strokeColor: '#000000',
        strokeWeight: 2.5,
        strokeOpacity: 0.9,
        icons: [
          {
            // Arrow icon
            icon: {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              strokeWeight: 1,
              strokeOpacity: 1,
              fillOpacity: 1,
              scale: 2.4,
            },
            offset: windowWidth < 1280 ? '50px' : '100px',
            repeat: '150px',
          },
        ],
      });
    }
  });

  // Zoom and center the map
  if (accommodations.length === 1) {
    map.setCenter({ lat: accommodations[0].gps_latitude, lng: accommodations[0].gps_longitude });
    map.setZoom(7);
  } else {
    const ne = mapBounds.getNorthEast();
    const sw = mapBounds.getSouthWest();
    const distance = calcDistance(sw.lat(), sw.lng(), ne.lat(), ne.lng());
    if (distance < 400) {
      mapBounds = new google.maps.LatLngBounds(
        { lat: sw.lat() - 0.5, lng: sw.lng() - 0.5, },
        { lat: ne.lat() + 0.5, lng: ne.lng() + 0.5, }
      );
    }
    map.fitBounds(mapBounds);
    infoWindow.addListener('closeclick', () => {
      // Opening the info popup can cause the map to pan in order to accommodate the popup,
      // so we make sure to pan back to the center when closing the popup
      map.panTo(mapBounds.getCenter());
    });
  }
}

function calcDistance(latA, longA, latB, longB) {
  if ((latA === latB) && (longA === longB)) {
    return 0;
  }
  const radLatA = Math.PI * latA / 180;
  const radLatB = Math.PI * latB / 180;
  const theta = longA - longB;
  const radTheta = Math.PI * theta / 180;
  const dist = Math.sin(radLatA) * Math.sin(radLatB) + Math.cos(radLatA) * Math.cos(radLatB) * Math.cos(radTheta);
  return Math.acos(Math.min(dist, 1)) * 180 / Math.PI * 60 * 1.1515 * 1.609344;
}

const pinIconPath = 'M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z';
