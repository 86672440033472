export default function PartiesScreen() {
  /**
   * Immutable store operations
   */
  var store = window.__INITIAL_STATE__;
  store.messages = {};
  store.showMessages = false;

  var mealKey = 'mealRequirements';
  var allergiesKey = 'allergies';
  var needsKey = 'specificNeeds';
  var weightKilogramsKey = 'weightKilograms';
  var weightPoundsKey = 'weightPounds'
  var contactNameKey = 'contactName';
  var contactNumberKey = 'contactNumber';
  var contactRelationKey = 'contactRelation';
  var contactEmailKey = 'contactEmail';
  var contactsKey = 'emergencyContacts';
  var travellerEmailKey = 'travellerEmail';

  const kgsPerPound = 2.20462;

  function addEntry(collectionKey, val) {
    store = _.assignIn({}, store);
    var collection = store[collectionKey] || [];
    store[collectionKey] = collection.indexOf(val) < 0
      ? collection.concat(val)
      : collection;
      render();
  }

  function removeEntry(collectionKey, val) {
    store = _.assignIn({}, store);
    var result = store[collectionKey].filter(function(entry) {
      return entry !== val;
    });
    store[collectionKey] = result.length ? result : undefined;
    render();
  }

  function clearEntries(collectionKey) {
    store = _.assignIn({}, store);
    store[collectionKey] = [];
    render();
  }

  function updateTextField(textFieldKey, value) {
    store = _.assignIn({}, store);
    store[textFieldKey] = value;
    render();
  }

  function showMessages() {
    store = _.assignIn({}, store);
    store.showMessages = true;
    render();
  }

  function getIncompleteCount() {
    return getRequiredFormKeys().reduce(function(total, key) {
      if (!store[key]) {
        return total + 1;
      }
      return total;
    }, 0);
  }

  function getRequiredFormKeys() {
    return [
      mealKey, allergiesKey, needsKey, contactNameKey, contactNumberKey,
      contactRelationKey, contactEmailKey
    ];
  }

  function validate() {
    var messages = {};
    var contactEmail = store[contactEmailKey]
    var travellerEmail = store[travellerEmailKey]
    if (contactEmail && !isEmailValid(contactEmail)) {
      messages[contactEmailKey] = 'Please enter a valid email';
    } 
    if (travellerEmail && !isEmailValid(travellerEmail)) {
      messages[travellerEmailKey] = 'Please enter a valid email';
    }

    return messages;
  }

  function loadConvertedWeight(weight) {
    const convertedWeight = convertKilogramsToPounds(weight);

    if (weight === "") {
      $("#weightLbs").val(null);
    } else {
      $("#weightLbs").val(convertedWeight);
    }
  }

  function convertKilogramsToPounds(weightEntry) {
    const roundingFactor = Math.pow(10, 1 - 1);

    const weight = Math.round((weightEntry * kgsPerPound) * roundingFactor) / roundingFactor;

    return weight;
  }

  function convertPoundsToKilograms(weightEntry) {
    const roundingFactor = Math.pow(10, 2 - 1);

    const weight = Math.round((weightEntry / kgsPerPound) * roundingFactor) / roundingFactor;

    return weight;
  }

  function isEmailValid(email) {
    // eslint-disable-next-line no-useless-escape, no-control-regex
    return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(email);
  }

  /**
   * Component setup
   */
  $('#profile-form').submit(function(e) {
    if (!_.isEmpty(store.messages)) {
      e.preventDefault();
      showMessages();
    }
  });

  var $mealGroup = $('#meal_requirements');
  var $allergiesGroup = $('#allergies');
  var $needsGroup = $('#specific_needs');
  $mealGroup.find('select').change(createSelectHandler(mealKey));
  $allergiesGroup.find('select').change(createSelectHandler(allergiesKey));
  $needsGroup.find('select').change(createSelectHandler(needsKey));

  $('#contact-name').on('input', createTextFieldHandler(contactNameKey));
  $('#contact-number').on('input', createTextFieldHandler(contactNumberKey));
  $('#contact-relation').on('input', createTextFieldHandler(contactRelationKey));
  $('#contact-email').on('input', createTextFieldHandler(contactEmailKey));
  $('#traveller-email').on('input', createTextFieldHandler(travellerEmailKey));
  $('#weightKg').on('input', function () {
    const enteredWeight = $("#weightKg").val();
    createTextFieldHandler(weightKilogramsKey);
    const convertedWeight = convertKilogramsToPounds(enteredWeight);

    if (enteredWeight === "") {
      $("#weightLbs").val(null);
    } else {
      $("#weightLbs").val(convertedWeight);
    }
  });
  $('#weightLbs').on('input', function () {
    const enteredWeight = $("#weightLbs").val();
    createTextFieldHandler(weightPoundsKey);
    const convertedWeight = convertPoundsToKilograms(enteredWeight);

    if (enteredWeight === "") {
      $("#weightKg").val(null);
    } else {
      $("#weightKg").val(convertedWeight);
    }
  });

  loadConvertedWeight($('#weightKg').val())

  function createSelectHandler(collectionKey) {
    return function onChange() {
      if (this.value === 'None') {
        clearEntries(collectionKey);
      } else {
        addEntry(collectionKey, this.value);
        // Set back to 'Select...' option
        this.value = 'default';
      }
    };
  }

  function createTextFieldHandler(key) {
    return function onChange() {
      updateTextField(key, this.value);
    };
  }

  var contactOptions = store[contactsKey].map(function(ec) {
    return {
      value: ec.name,
      label: ec.name,
      datum: ec,
    };
  });
  $('#contact-name').autocomplete({
    minLength: 0,
    source: contactOptions,
    open: function() {
      // Fix for ios - requires clicking twice to select without the below
      if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
        $('.ui-autocomplete').off('menufocus hover mouseover');
      }
    },
    select: function(ev, ui) {
      var emergencyContact = ui.item.datum;
      updateTextField(contactNameKey, emergencyContact.name);
      if (emergencyContact.number) {
        $('#contact-number').val(emergencyContact.number).trigger('input');
      }
      if (emergencyContact.email) {
        $('#contact-email').val(emergencyContact.email).trigger('input');
      }
    }
  });

  /**
   * Component rendering
   */
  var prevStore = {};
  function render() {
    store.incompleteFieldCount = getIncompleteCount();
    store.messages = validate();

    if (prevStore[mealKey] !== store[mealKey]) {
      renderTags($mealGroup, mealKey);
    }

    if (prevStore[allergiesKey] !== store[allergiesKey]) {
      renderTags($allergiesGroup, allergiesKey);
    }

    if (prevStore[needsKey] !== store[needsKey]) {
      renderTags($needsGroup, needsKey);
    }

    if (prevStore.incompleteFieldCount !== store.incompleteFieldCount) {
      renderCount();
    }

    if (store.showMessages) {
      renderValidations(store.messages);
    }

    prevStore = store;
  }

  function renderCount() {
    if (store.incompleteFieldCount > 0) {
      $('#incomplete-fields').show();
      $('#incomplete-field-count').text(store.incompleteFieldCount);
      $('#incomplete-field-message').text(getIncompleteMessage(store.incompleteFieldCount));
    } else {
      $('#incomplete-fields').hide();
    }
  }

  function getIncompleteMessage(incompleteFieldCount) {
    if (incompleteFieldCount === 0) {
      return null;
    }
    if (incompleteFieldCount > 1) {
      return `There are ${incompleteFieldCount} outstanding fields in this profile, please complete these.`;
    }
    return 'There is 1 field outstanding in this profile, please complete this.';
  }

  function renderTags($group, collectionKey) {
    var $panel = $group.find('div[data-type="panel"]');
    // Hidden fields is what will get posted to server on submit
    var $hiddenFields = $group.find('div[data-hidden-fields]');

    $panel.empty();
    $hiddenFields.empty();

    var values = store[collectionKey];
    if (!values) {
      return;
    }

    var tags = getTags(values, collectionKey);
    $panel.append(tags);

    var fieldName = $hiddenFields.data('name');
    var hiddenInputs = values.map(function(val) {
      return getHiddenInput(fieldName, val);
    });
    if (!hiddenInputs.length) {
      // This corresponds to the 'None' entry
      hiddenInputs = getHiddenInput(fieldName, '');
    }
    $hiddenFields.append(hiddenInputs);
  }

  function getTags(collection, collectionKey) {
    return collection.map(function(val) {
      var tag = document.createElement('span');
      tag.className = 'label label-default margin-right';
      tag.innerText = val;

      var closeButton = document.createElement('span');
      closeButton.className = 'tag-btn-close margin-left';
      closeButton.innerText = 'x';
      closeButton.onclick = function() {
        removeEntry(collectionKey, val);
      };
      tag.appendChild(closeButton);
      return tag;
    });
  }

  function getHiddenInput(fieldName, val) {
    var hiddenInput = document.createElement('input');
    hiddenInput.type = 'hidden';
    hiddenInput.name = 'client_profile[' + fieldName + '][]';
    hiddenInput.value = val;
    return hiddenInput;
  }

  function renderValidations(messages) {
    $('#traveller-email-message').text(messages[travellerEmailKey] || '');
    $('#contact-email-message').text(messages[contactEmailKey] || '');
  }

  render();
}
