export default function AcceptanceScreen() {
  $('.u-date-input').dateInput();
  var showingMessages = false;
  var $travellerDetailsNotice = $('#traveller-details-required');

  $('.acceptance-form').formValidator({
    onSubmitError: function () {
      showingMessages = true;
      $travellerDetailsNotice.show();
    },
    onValidate: function (messages) {
      if (!showingMessages) {
        return;
      }

      if (_.isEmpty(messages)) {
        $travellerDetailsNotice.hide();
      } else {
        $travellerDetailsNotice.show();
      }
    }
  });

  // Checking/unchecking terms
  $('#accept-terms').click(function () {
    checkConfirmButtonStatus();
  });
  $('#accept-proposal').click(function () {
    checkConfirmButtonStatus();
  });

  // DOB change confirmation
  $('#dates-yes-btn').click(function() {
    $('#new_acceptance').submit();
  });
  $('#dates-no-btn').click(function() {
    $('#confirm-modal').modal('hide');
  });

  function checkConfirmButtonStatus() {
    if ($('#accept-terms').is(':checked') && $('#accept-proposal').is(':checked')) {
      $('#submit-btn').prop('disabled', false);
    } else {
      $('#submit-btn').prop('disabled', true);
    }
  }
}
