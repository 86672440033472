export function initModals() {
  initTabNavigation();

  var stopVideos = function(element) {
    $(element).find('iframe.gallery-video').each(function() {
      var $iframe = $(this);
      var src = $iframe.attr('src');
      $iframe.attr('src', '');
      $iframe.attr('src', src);
    });
  };

  $('.modal .nav-tabs a[data-gallery-tab]').on('hidden.bs.tab', function () {
    var galleryTabPane = $('#' + $(this).attr('aria-controls'));
    stopVideos(galleryTabPane);
  });

  $('.modal').on('hidden.bs.modal', function () {
    stopVideos(this);
  });

  $('.modal').on('shown.bs.modal', function () {
    // Ensure all images in the modal have been loaded
    // Eg. we defer the loading of gallery images until this point (all except the very first gallery image)
    $(this).find('img[data-src]').imageLoader();
  });

  $('.modal .carousel').on('slid.bs.carousel', function() {
    stopVideos(this);
  });
}

function initTabNavigation() {
  $('.modal .nav-tabs').tinyNav({
    active: 'active'
  });

  $('.tinynav').change(function () {
    $('#a-' + $(this).val().substring(1, $(this).val().length)).tab('show');
  });

  $('.modal .nav-tabs a').click(function (e) {
    e.preventDefault();
    $(this).tab('show');
  });
}
