export default function addImageLoaderPlugin($) {
  $.fn.imageLoader = function () {
    this.each(function () {
      initImageLoader($(this));
    });
  };
}

function initImageLoader(elem) {
  const src = elem.data('src');
  if (!src) {
    return;
  }

  elem.on('load', () => elem.removeAttr('data-src'));
  elem.attr('src', src);
}
