export default function addBackgroundImageLoaderPlugin($) {
  $.fn.backgroundImageLoader = function () {
    this.each(function () {
      initBackgroundImageLoader($(this));
    });
  };
}

function initBackgroundImageLoader(elem) {
  const backgroundUrl = elem.data('bg-url').replace(/ /g, '%20');
  if (!backgroundUrl) {
    return;
  }
  const backgroundType = elem.data('bg-type');
  const { url, blurredUrl } = getResponsiveImage(backgroundUrl, backgroundType);

  let imageLoaded = false;
  const imageLoaderElem = $('<img>');
  imageLoaderElem.on('load', function () {
    imageLoaded = true;
    setBackgroundImage(elem, url);
    // Add 20ms to avoid Firefox's image flicker bug
    setTimeout(() => elem.addClass('bg-loaded'), 20);
    if (backgroundType) {
      let timer;
      $(window).resize(() => {
        clearTimeout(timer);
        timer = setTimeout(() => applyResponsiveImage(elem, backgroundUrl, backgroundType), 300);
      });
    }
  });
  imageLoaderElem.attr('src', url);

  // Show a small blurred version of the image while the full res image loads
  const imageCached = imageLoaderElem[0].complete;
  if (imageCached || blurredUrl === url) {
    return;
  }

  const blurredImageLoaderElem = $('<img>');
  blurredImageLoaderElem.on('load', function () {
    if (imageLoaded) {
      return;
    }
    const blurredImageElem = $('<div class="blurred-bg-img">');
    setBackgroundImage(blurredImageElem, blurredUrl);
    elem.prepend(blurredImageElem);
  });
  blurredImageLoaderElem.attr('src', blurredUrl);
}

function getResponsiveImage(fullVersion, imageType) {
  const windowWidth = $(window).width();
  if (imageType && windowWidth < 415) {
    return {
      url: addTransformation(fullVersion, `t_mobile_${imageType}_image`),
      blurredUrl: addTransformation(fullVersion, `t_mobile_${imageType}_image/t_blurred_thumb`)
    };
  }
  return {
    url: fullVersion,
    blurredUrl: addTransformation(fullVersion, 't_medium_blurred_thumb')
  };
}

function addTransformation(url, trans) {
  return url.replace(/\/image\/upload\//, `/image/upload/${trans}/`);
}

function applyResponsiveImage(elem, fullVersion, imageType) {
  const image = getResponsiveImage(fullVersion, imageType);
  setBackgroundImage(elem, image.url);
}

function setBackgroundImage(elem, url) {
  elem.css('background-image', `url(${url})`);
}

