import AccountModal from '../account-modal';
import { initGoogleMap } from './map';

export default function TripOverviewScreen() {
  AccountModal();
  const mapElement = document.getElementById('overview-map');
  if (mapElement) {
    initGoogleMap(mapElement);
  }
}
