import { isPresent, isDate, isNotInFuture } from './validation-rules';

export function createFieldValidator(inputElem) {
  const validators = getValidators(inputElem);

  return function validate() {
    const value = inputElem.val();
    for (let i = 0; i < validators.length; i++) {
      const validator = validators[i];
      const message = validator(value);
      if (message) {
        return message;
      }
    }
    return undefined;
  }
}

function getValidators(inputElem) {
  const validators = [];

  if (inputElem.data('required')) {
    validators.push(isPresent)
  }

  // New field types can be handled here (with their restrictions)
  const fieldType = inputElem.data('type') || '';
  switch (fieldType) {
    case 'date':
      validators.push(isDate);
      if (inputElem.data('rule-restrict-future')) {
        validators.push(isNotInFuture);
      }
      break;

    default:
      break;
  }

  return validators;
}

