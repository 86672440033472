import { round, formatNumber } from '../utils/number-utils';

const defaultConfig = { showAmountDue: true };

export default function addCurrencyInputPlugin($) {
  $.fn.currencyInput = function (config) {
    config = _.assignIn({}, defaultConfig, config);

    return this.each(function () {
      initInput($(this), config);
    });
  };
}

function initInput(inputElem, config) {
  const { originalAmount, formattedOriginalAmount, currencyCode } = initData(inputElem);

  inputElem.wrap(`
    <span class="currency-input-wrapper">
      <span class="amount-label"></span>
      ${config.showAmountDue ? '<span class="amount-due"></span>' : ''}
    </span>
  `);
  const wrapper = inputElem.parent();

  // Init labels
  const amountLabel = wrapper.find('.amount-label');
  const amountDueLabel = wrapper.find('.amount-due');
  amountLabel.text(formattedOriginalAmount);
  amountDueLabel.text(`Due ${formattedOriginalAmount} ${currencyCode}`);

  // Init handlers
  amountLabel.on('click', () => inputElem.focus());
  inputElem.on('focus', () => amountLabel.hide());

  inputElem.on('input', () => {
    const parsed = round(inputElem.val());
    setAmountLabel(parsed);
    checkAmountDue(parsed);
  });

  inputElem.on('blur', () => {
    let parsed = round(inputElem.val());
    if (isNaN(parsed) || parsed < 0) {
      parsed = 0;
    }

    setInputValue(parsed);
    setAmountLabel(parsed);
    checkAmountDue(parsed);
    amountLabel.show();
  });

  function setInputValue(parsed) {
    inputElem.val(parsed.toFixed(2));
  }

  function setAmountLabel(parsed) {
    amountLabel.text(format(parsed));
  }

  function checkAmountDue(parsed) {
    parsed === originalAmount ? amountDueLabel.fadeOut() : amountDueLabel.fadeIn();
  }

  // Kick off
  setInputValue(originalAmount);
}

function initData(inputElem) {
  const amount = inputElem.data('amount');
  const originalAmount = amount && round(amount) || 0;
  const formattedOriginalAmount = format(originalAmount);
  const currencyCode = inputElem.data('currency-code') || '';
  return {
    originalAmount, formattedOriginalAmount, currencyCode
  };
}

function format(parsed) {
  return isNaN(parsed) ? '0.00' : formatNumber(parsed);
}
