export function isPresent(val) {
  val = _.isString(val) ? val.trim() : val;
  return _.isEmpty(val) ? 'This is required' : undefined;
}

// Validates that the input string is a valid date formatted as "YYYY-MM-DD"
export function isDate(dateString) {
  var message = 'This date is invalid';
  // First check for the pattern
  // eslint-disable-next-line no-useless-escape
  if (!/^\d{4}\-\d{1,2}\-\d{1,2}$/.test(dateString)) {
    return message;
  }

  // Parse the date parts to integers
  var parts = dateString.split('-');
  var day = parseInt(parts[2], 10);
  var month = parseInt(parts[1], 10);
  var year = parseInt(parts[0], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) {
    return message;
  }

  var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  // Check the range of the day
  return (day > 0 && day <= monthLength[month - 1]) ? undefined : message;
}

export function isNotInFuture(dateString) {
  var selectedDate = new Date(dateString);
  selectedDate.setHours(0, 0, 0, 0);
  var now = new Date();
  now.setHours(0, 0, 0, 0);
  return selectedDate > now ? 'This date cannot be in the future' : undefined;
}
