import addImageLoaderPlugin from './image-loader';
import addBackgroundImageLoaderPlugin from './background-image-loader';
import addValidatePlugin from './form-validator';
import addDateInputPlugin from './date-input';
import addCurrencyInputPlugin from './currency-input';

export default function addPlugins() {
  addImageLoaderPlugin(jQuery);
  addBackgroundImageLoaderPlugin(jQuery);
  addValidatePlugin(jQuery);
  addDateInputPlugin(jQuery);
  addCurrencyInputPlugin(jQuery);
}

